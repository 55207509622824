<template>
  <v-card outlined elevation="0">
    <v-col cols="12" class="">
      
      <v-card-title class="pt-0">
        n° {{ wallet.uid }}
      </v-card-title>

    
      <v-card-subtitle v-if="wallet.type=='MAIN'" class="pb-2">
        <v-icon small class="mr-1">mdi-credit-card-clock</v-icon>
        Compte courant
      </v-card-subtitle>
      <v-card-subtitle v-if="wallet.type=='DEPOSIT'" class="pb-2">
        <v-icon small class="mr-1">mdi-credit-card-settings</v-icon>
        <template v-if="wallet.name == 'DEPOSIT'">Compte de dépot</template>
        <template v-else>{{ wallet.name }}</template>
      </v-card-subtitle>

      <v-divider></v-divider>

      <v-card-text class="pl-2 pb-0 pt-2">
        <v-btn x-small outlined fab color="primary" 
              title="afficher les transactions de ce compte" 
              @click="showDialogTransaction()"
              class="black--text mb-1 mr-2">
          <v-icon small>mdi-chart-line</v-icon>
        </v-btn>
        <template v-if="wallet.type == 'MAIN'">
          <span class="mr-2 font-led primary--text" style="font-size:20px;">
            <b>{{ convertUnityMony(wallet.amountUnity).toFixed(4) }} {{ $store.state.app.monyUnity }}</b>
          </span>
          <div class="d-inline-block">~{{ monyToEuro(convertUnityMony(wallet.amountUnity)).toLocaleString() }} €</div>
        </template>
        <template v-if="wallet.type == 'DEPOSIT'">
          <span class="mr-2 font-led primary--text" style="font-size:20px;">
            <b>{{ wallet.amountMony.toFixed(4) }} {{ $store.state.app.monyUnity }}</b>
          </span>
          <div class="d-inline-block">~{{ monyToEuro(wallet.amountMony).toFixed(2) }} €</div>
        </template>
      </v-card-text>

    </v-col>

    <v-dialog v-model="showWalletTransaction"
              max-width="800" content-class="dialog-large-bordered" scrollable
              :fullscreen="$vuetify.breakpoint.width < 960">
      <v-card color="rgb(18, 18, 18)">
        <v-card-title class="text-center">
          Compte n° {{ wallet.uid }}<br>
          <span class="primary--text font-papyrus">{{ ownerName }}</span>
        </v-card-title>
        <v-btn class="d-md-none mx-5 mb-5" @click="showWalletTransaction = false">Fermer</v-btn>
        <v-divider></v-divider>
        <v-card-title class="font2x font-led primary--text text-center">
          {{ convertUnityMony(wallet.amountUnity).toFixed(4) }} PRT
        </v-card-title>

        <div class="d-block" style="height:200px;" v-if="showWalletTransactionOk">
        <ChartWallet :height="200"></ChartWallet> 
        </div>
        
        <v-card-text class="py-3 px-0" id="wallet-container">
          <template v-for="(transaction, nTrans) in wallet.transactions">
            <template v-if="nTrans < 366">
              <TransactionCard :key="nTrans" :transaction="transaction" :showDaily="true"></TransactionCard>
            </template>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>


<script>

// import axios from "axios"
// import { i18n } from 'vue-lang-router';
import core from '../../plugins/core.js'
const config = require('../../config/' + process.env.NODE_ENV)
import TransactionCard from '@/components/wallet/TransactionCard.vue'
import ChartWallet from '@/components/charts/ChartWallet.vue'

export default {
  name: 'WalletCard',
  components: { TransactionCard, ChartWallet },
  props:['wallet', 'ownerName'],
  data: () => ({
    showWalletTransaction: false,
    showWalletTransactionOk: false
  }),
  mounted: async function(){
    this.initData()
  },
  methods: {
    async initData(){
    },
    showDialogTransaction(){
      this.showWalletTransaction = true
      this.showWalletTransactionOk = false
      this.$store.dispatch("auth/setFocusWalletUid", { uid : this.wallet.uid })
      setTimeout(()=>{ this.showWalletTransactionOk = true }, 50)
    },
    monyToEuro(amount){ return amount * config.monyToEuro },

    convertUnityMony(amountUnity){
      return core.convertUnityMony(amountUnity, this.$store.state.auth.monyConvertValue)
    },
    convertMonyUnity(amountMony){
      return core.convertMonyUnity(amountMony, this.$store.state.auth.monyConvertValue)
    },
    
  },
  computed: {
  },
  watch: {
      
  }, 
}
</script>