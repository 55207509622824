<template>
<v-col cols="12" md="9" lg="8" class="mx-auto" style="padding-bottom:150px;">
  <v-row class="mx-0">

    <v-col cols="12" sm="11" md="10" lg="9" class="text-center mx-auto header-section">
      <v-icon class="d-none d-md-flex" style="font-size:6em; margin-bottom:-70px;" color="primary">mdi-wallet-bifold-outline</v-icon>
      <v-icon class="d-md-none" style="font-size:4em; margin-bottom:-20px;" color="primary">mdi-wallet-bifold-outline</v-icon>
      <h2 class="mt-3 ml-4 primary--text">Comptes Publics</h2>
      <small class="font-papyrus d-block" style="margin-top:50px;" @click="fetchPublicWallets()">
        Dans un souci de transparence, le solde des Comptes Publics est accessible à tous.
      </small>
      <v-divider class="my-5"></v-divider>
      Taux de conversion actuel<br>1 PRT = <b>{{ $store.state.auth.monyConvertValue.toFixed(11) }}</b> ù
      <v-divider class="my-5"></v-divider>
    </v-col>

    <template v-for="user in $store.state.app.publicWallets">
      <v-col :key="user._id" cols="12" md="6" class="">
        <h2 class="pa-3 text-center font-papyrus primary--text" 
            style="border:1px dashed #fbc02d; border-radius:0px;border-radius:8px;">
          <v-icon color="primary">mdi-wallet</v-icon><br>
          {{ user.name }}
        </h2>
        <div class="d-block text-center">
          <v-icon color="primary darken-1" large class="mb-1">mdi-chevron-down</v-icon>
        </div>
        <WalletCard :ownerName="user.name" :wallet="user.walletMain"></WalletCard>
        <div class="d-block text-center">
          <v-icon color="grey darken-4" large class="pa-0" 
                  style="margin-top:-3px; margin-bottom:-3px; transform: rotate(90deg)">
                  mdi-pipe
          </v-icon>
        </div>
        <WalletCard :ownerName="user.name" :wallet="user.walletsDeposit[0]"></WalletCard>
        <br><br><br>
      </v-col>
    </template>
  </v-row>
</v-col>
</template>

<style>
  .icon-link{
    background-color: #1e1e1e;
    border-radius: 50%;
    margin: -6px;
    z-index: 5;
    border: 1px solid #393939;
  }
</style>

<script>
import WalletCard from '@/components/wallet/WalletCard.vue'
//import UserPage from '@/components/entity/UserPage.vue'
//import axios from "axios"

export default {
  name: 'public-wallets',
  props: [],
  components: {
    WalletCard
  },
  data: () => ({
    
  }),
  mounted: async function(){
    this.fetchPublicWallets()
  },
  methods: {
    fetchPublicWallets(){
    this.$store.dispatch('app/incStat', '/public-wallets')
      this.$store.dispatch('app/fetchPublicWallets')
    }
  },
  watch: {
      
      
  }
  
}
</script>
